import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
import store from "./redux/store/index";
import { Provider } from "react-redux";
import {UseWalletProvider} from 'use-wallet';


ReactDOM.render(
  <UseWalletProvider 
  autoConnect
    connectors={{
      injected: {},
      walletconnect: {
        rpc: {
          56: "https://bsc-dataseed.binance.org/"
        },
        bridge: 'https://bridge.walletconnect.org',
        pollingInterval: 12000,
      },
    }}>
  <Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
    <App />
  </BrowserRouter>,
    </React.StrictMode>
  </Provider>
  </UseWalletProvider>
  ,
  document.getElementById('root')
);

// reportWebVitals();