import BEP20Abi from '../Config/abis/BEP20Abi.json';
import crowdsaleAbi from '../Config/abis/hotFry.json';
import { getAddress } from './addressHelper';
import contracts from './../Config/contracts';
import Web3 from 'web3';
// import {useWallet} from "use-wallet";

let chainID = 56;

export const getChainID = (chainidx) => {
    chainID = chainidx;
    console.log(chainID);
}

export const getBusdContract = async (provider) => {
    try {
        //let web3 = await getWeb3();
        let web3;
        //const provider = await detectEthereumProvider();
        if (provider) {
            web3 = new Web3(provider);
            console.log("here", web3);

            let BUSD = new web3.eth.Contract(
                BEP20Abi,
                getAddress(contracts.busd)
            );
            console.log(BUSD);
            return BUSD;
        } else {
            web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));

            console.log("here", web3);

            let BUSD = new web3.eth.Contract(
                BEP20Abi,
                getAddress(contracts.busd)
            );
            console.log(BUSD);
            return BUSD;
        }
    }
    catch (e) {
        console.log(e)
    }

}

export const getBUSDBalance = async (accountx) => {
    try {
        const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));
        const BUSD = new web3.eth.Contract(BEP20Abi,getAddress(contracts.busd));
        const HFC = new web3.eth.Contract(crowdsaleAbi,getAddress(contracts.crowdsale));
        const Bdecimal = await BUSD.methods.decimals().call()
        const Hdecimal = await HFC.methods.decimals().call()
        const BUSDBalance = (await BUSD.methods.balanceOf(accountx).call() / (10 ** Bdecimal));
        const HFCBalance = (await HFC.methods.balanceOf(accountx).call() / (10 ** Hdecimal));
        const BalanceData = {"HFC":HFCBalance,"BUSD":BUSDBalance};
        return BalanceData;
    }
    catch (e) {
        console.log(e)
    }
}

export const getCrowdsaleContract = async (provider) => {
    try {
        //let web3 = await getWeb3();
        //const provider = await detectEthereumProvider();
        let web3;
        if (provider) {
            web3 = new Web3(provider);
        } else {
            web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));
        }
        //let web3 = new Web3(new Web3.providers.HttpProvider());
        console.log("here", web3);
        let crowdsale = new web3.eth.Contract(
            crowdsaleAbi,
            getAddress(contracts.crowdsale)
        );
        console.log(crowdsale);

        return crowdsale;
    }
    catch (e) {
        console.log(e)
    }
}

export const getCrowdsaleContract_2 = async () => {
    try {

        let web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));

        //let web3 = new Web3(new Web3.providers.HttpProvider());
        console.log("here", web3);
        let crowdsale = new web3.eth.Contract(
            crowdsaleAbi,
            getAddress(contracts.crowdsale)
        );
        console.log(crowdsale);

        return crowdsale;
    }
    catch (e) {
        console.log(e)
    }
}

export const getAllowance = async (account, provider) => {
    try {
        let web3;
        //let web3 = await getWeb3();
        //const provider = await detectEthereumProvider();
        if (provider) {
            web3 = new Web3(provider);
        } else {
            web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/"));
        }
        let token = await getBusdContract();
        let crowdsaleAddress = await getAddress(contracts.crowdsale);
        let allowance = await token.methods
            .allowance(account, crowdsaleAddress)
            .call();
        console.log(
            "allowance is",
            await web3.utils.fromWei(allowance.toString(), "ether")
        );
        return await web3.utils.fromWei(allowance.toString(), "ether");
    } catch (e) {
        console.log(e);
    }
};

export const checkIfApproved = async (inputAmount,account,provider) => {
    try {
        let allowance = await getAllowance(account, provider);
        console.log("allowances", inputAmount, allowance);
        if (Number(allowance) < Number(inputAmount) || Number(allowance) <= 0) {
            console.log("false");
            return false;
        } else {
            console.log("true");
            return true;
        }
    } catch (e) {
        console.log(e);
    }

};

//Price Call for 

export const getPrice = async () => {
    let contract = await getCrowdsaleContract_2()
    console.log("contract in get price----->",contract);
    let price = await contract.methods.price().call();
    console.log("price-------->", price )/// 1e8
    return (price / 1e18).toString();
}


//Earning Call on Home page

export const getEarning = async () => {
    let contract = await getCrowdsaleContract_2()
    console.log("contract in get price----->",contract);
    let earning = await contract.methods.Earning().call();
    console.log("eaning-------->", earning )/// 1e8
    return (earning / 1e18).toString();
}

//Total Token for Market Cap

export const getTotalSupply = async () => {
    let contract = await getCrowdsaleContract_2()
    console.log("contract in get total Supply----->",contract);
    let totalSupply = await contract.methods.totalSupply().call();
    console.log("TotalSupply-------->", totalSupply )/// 1e8
    return (totalSupply / 1e18).toString();
}
