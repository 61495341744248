
import { getAddress } from "./addressHelper";
import contracts from "../Config/contracts";
import { getCrowdsaleContract,getBusdContract } from './getterFunctions'
import BigNumber from 'bignumber.js';
import Web3 from "web3";


export const buyToken = async (tokenAmount, referrer, account, provider) => {
    //const provider = await detectEthereumProvider();
      let web3 = new Web3(provider);
      //let web3 = await getWeb3();
    let contract = await getCrowdsaleContract(provider);
    
    let value = 0;
    
    console.log("account in buytoken function is --->",account);

    // big Number
    
    value = new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(new BigNumber(18)));
    console.log("value--------->", contract)
    // tokenAmount=tokenAmount.toString()
    tokenAmount = await web3.utils.toWei(tokenAmount.toString())
    console.log("token ammount is ----->",tokenAmount)
    let res = await contract.methods.buy(tokenAmount,referrer).send({ from: account, value: value })
    return res;
}

export const approveTokens = async(account, provider) => {
   
    let crowdsaleAddress = await getAddress(contracts.crowdsale);
     let tokenAmount = "10000000000000000000000000000000000000000000000000000000";
    let contract=await getBusdContract(provider);
    let res = await contract.methods.approve(crowdsaleAddress, tokenAmount).send({ from: account })
    return res;
}


export const sellToken = async(sellAmmount, account, provider) =>{
    let web3 = new Web3(provider);
    let contract =await getCrowdsaleContract(provider);
    let value = 0;

    console.log("account in buytoken function is --->",account);

// big Number

    value = new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(new BigNumber(18)));
    console.log("value--------->", contract)
    // tokenAmount=tokenAmount.toString()
    sellAmmount = await web3.utils.toWei(sellAmmount.toString())

    let res =await contract.methods.sell(sellAmmount).send({from:account})
    return res;
}

export const transferAmount = async(recipient,amount,account, provider) =>{
    //let web3 = await getWeb3();
    let contract =await getCrowdsaleContract(provider);
    let value = 0;

    console.log("account in trasfer  function is --->",account);

// big Number

   
    console.log("value--------->", contract)
    // tokenAmount=tokenAmount.toString()
   

    let res =await contract.methods.transfer(recipient,amount).send({from:account})
    return res;
}
