const contracts = {
  crowdsale: {
    56: "0x849741B79bc1618b46CF9ec600E94E771DEde601",
    97: "0x849741B79bc1618b46CF9ec600E94E771DEde601",
  },
  busd: {
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    97: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
};

export default contracts