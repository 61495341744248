import Chart , {Utils} from 'chart.js/auto';
import { useEffect } from 'react';

const labels = [
    "12/16/2021 11:50",
    "12/17/2021 5:48",
    "12/19/2021 22:16",
    "12/25/2021 5:21",
    "12/31/2021 10:10",
    "1/2/2022 23:08",
    "1/3/2022 18:51",
    "1/9/2022 22:09",
    "1/10/2022 0:38",
    "1/17/2022 22:32",
    "1/24/2022 0:32",
    "2/1/2022 18:28",
    "2/2/2022 0:13",
    "2/4/2022 7:49",
    "2/7/2022 19:27",
    "2/10/2022 4:12",
    "2/13/2022 22:01",
    "2/23/2022 7:11",
    "2/23/2022 18:38"
];

const data = {
    labels:labels,
    datasets:[{
        label:'Price Chart',
        data:[
            1.08,
            1.094617473,
            1.162672129,
            1.203933131,
            1.20777316,
            1.230018665,
            1.230986281,
            1.266853165,
            1.279715804,
            1.296311687,
            1.301270873,
            1.309286151,
            1.312734864,
            1.316537475,
            1.510590588,
            1.511922517,
            1.51246066,
            1.514172047,
            1.517040354
        ],
        fill:false,
        borderColor:'rgb(218,43,43)',
        tension:0.1,
    }]
};


const config = {type:'line',data:data};

setTimeout(() => {
    const ctx = document.getElementById('myChart');
    const myChart = new Chart(ctx,config);
},100);

export default function PriceChart() {
    return (
        <>
            <canvas id="myChart"></canvas>
        </>
    );
}
