import { React, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useWallet } from "use-wallet";
import { ToastContainer, toast } from "react-toastify";
import CoinDivider from "../../assets/img/divider-coin.svg";

export default function WalletModal(props) {
  let wallet = useWallet();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className="nav-link conectwlbtn"
        variant="danger"
        style={{ color: props.color }}
        onClick={() => handleShow()}
      >
        {wallet.account ? wallet.account.slice(0, 5) : "Connect Wallet"}
      </Button>
      <Modal
        show={show}
        onHide={() => handleClose()}
        backdrop="static"
        centered
        className="loginmodal"
      >
        <Modal.Body className="border-0 p-3 p-sm-3 p-md-4 text-center">
          <p className="fs-22 color-grey fw-bold text-center text-uppercase">
            Connect Wallet Using
          </p>
          <Image src={CoinDivider} className="img-fluid pb-3 mb-3" />
          {wallet.status === "connected" ? (
            <div>
              <div>Account: {wallet.account}</div>
              <div>Balance: {wallet.balance}</div>
              <button onClick={() => wallet.reset()}>Disconnect</button>
            </div>
          ) : (
            <div className="text-center">
              <div>
                <Button
                variant="danger"
                  onClick={async () => {
                    await wallet.connect("injected");
                    window.sessionStorage.setItem(
                      "Selected_account",
                      wallet.account
                    );
                    window.sessionStorage.setItem("Provider", "metamask");

                    let res = wallet.status === "connected";
                    if (res.status == true) {
                      toast.success("Metamask Connected successful");
                      window.location.reload();
                    } else {
                      toast.error("Connection Failed");
                    }
                    window.location.reload();
                  }}
                >
                  MetaMask
                </Button>
              </div>
              <div className="color-grey fw-bold my-3">OR</div>
              <div>
                <Button
                variant="danger"
                  onClick={async () => {
                    await wallet.connect("walletconnect");
                    window.sessionStorage.setItem(
                      "Selected_account",
                      wallet.account
                    );
                    window.sessionStorage.setItem("Provider", "walletconnect");
                    window.location.reload();
                  }}
                >
                  WalletConnect
                </Button>
              </div>
            </div>
          )}
          <p className="fs-16 color-grey fw-bold pt-3 pt-md-4 text-center">
            Matamask button is only available for desktop and Dapp browsers. To
            access your metamask wallet use a desktop or walletconnect on
            browser like chrome and Safari.
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="outline-warning"
            style={{ color: props.color }}
            onClick={() => handleClose()}
          >
            cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
