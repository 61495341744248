
import Web3 from "web3";
import crowdsaleAbi from '../Config/abis/hotFry.json';
import { getAddress } from './addressHelper';
import contracts from './../Config/contracts';
var BigNumber = require('bignumber.js');
// import detectEthereumProvider from "@metamask/detect-provider";
// import WalletConnectProvider from "@walletconnect/web3-provider";
//import { Web3_provider_2, global_provider } from "../Component/Layout/Header";

//const provider = detectEthereumProvider();
//  Create WalletConnect Provider
// const global_provider = new WalletConnectProvider({
//     infuraId: "1b6b6b3459dc49a78c408ecc11ff6543",

//     rpc: {
//         56: "https://bsc-dataseed.binance.org/",
        
//       },
// });


export const getUserBalance = async (provider) => {
    try {
        //const web3 = await getWeb3();
        const web3 = new Web3(provider)
        let crowdsale = new web3.eth.Contract(
        crowdsaleAbi,
        getAddress(contracts.crowdsale)
      );
        const accountAddress = await getAccount(provider)
        let balance = await crowdsale.methods.balanceOf(accountAddress).call();
        balance = new BigNumber(balance).div(new BigNumber(10).exponentiatedBy(18))
        console.log("userbalance is",balance)
        return Number(balance)
    }
    catch (err) {
        console.log("error", err)
        return 0;
    }
}
/*
export const getWeb3 = async () => {
    try {
        let provider;
        let web3;
        web3 = await Web3_provider_2();
        if (web3) {
            return web3;
        } else {
            provider = await detectEthereumProvider();
            web3 = new Web3(provider);
            return web3;
        }
    }
    catch (err) {
        console.log("error", err)
    }
}
*/
export const getAccount = async (provider) => {
    try {
        //const web3 = await getWeb3()
        const web3 = new Web3(provider);
        const accountAddress = await web3.eth.getAccounts()
        return accountAddress[0]
    }
    catch (err) {
        console.log("error", err)
    }
}