import { React, useState, useEffect, useRef, useCallback } from "react";
import { Tabs, Tab, Button, Form, InputGroup, FormControl, } from "react-bootstrap";
import { RiArrowUpDownFill } from "react-icons/ri";
import { connect } from "react-redux";
import BigNumber from 'bignumber.js';
import { buyToken, approveTokens, sellToken, transferAmount } from "../../helpers/setterFunctions";
import { ToastContainer, toast } from "react-toastify";
// import { convertToInternationalCurrencySystem } from "../../helpers/numberFormatter"
import { getAllowance , checkIfApproved , getPrice } from "../../helpers/getterFunctions";
import Web3 from "web3";
// import { getWeb3 } from "../../helpers/currentWalletHelper";
// import detectEthereumProvider from "@metamask/detect-provider";
//import Web3_provider from "../header/Accountmodal";
import {useWallet} from "use-wallet";


const WalletTab = (props) => {
    const DEFAULT_ADDRESS = "0x1bd2122BD5f45B38f8123D1B67E890f7dd5C56AA"; //"0x7BCa14c72A1Ff37C4101BE62d91911669997f733";
    const [account, setAccount] = useState("");
    const [key, setKey] = useState("CoinBuy");
    const [outputAmount, setOutputAmount] = useState("");
    const [inputAmount, setInputAmount] = useState("");
    const [transactionOnGoing, setTransactionOnGoing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [price, setPrice] = useState("");
    const referInput = useRef(null);
    const [refLink, setRefLink] = useState("");
    const [amtTrxFrom, setAmtTrxFrom]=useState("")
    const [ref, setRef] = useState(DEFAULT_ADDRESS);
    const [copySuccess, setCopySuccess] = useState("");
    const [sellAmount,SetSellAmount]=useState("");
    const [sellOutputAmount, setSellOutputAmount] = useState("");
    const [buttonName,setButtonName]=useState("");
    const [recAddress,setRecAddress]=useState("");
    const [transferValue,setTransferValue]=useState("");
    const [Msg,setMsg]=useState("");
    const wallet = useWallet();

    useEffect(async() => {
        //const provider = await detectEthereumProvider();
        //let web3 = await getWeb3();
        let web3 = new Web3(wallet.ethereum);
        if(wallet.status === "connected"){
            //web3 = new Web3(provider);
            //const accounts = await web3.eth.getAccounts();
            const accounts = [wallet.account];
            console.log("account is ----->",accounts);
            setAccount(accounts[0]);
        } else {
            if(window.sessionStorage.getItem("Selected_account") && window.sessionStorage.getItem("Provider") == "walletconnect"){
                await wallet.connect("walletconnect");
                if (wallet.status === "connected") {
                    web3 = new Web3(wallet.ethereum);
                    const accounts = [wallet.account];
                    console.log("account is ----->",accounts[0])
                    setAccount(accounts[0]);
                } 
            }else {
                if(window.sessionStorage.getItem("Selected_account")) {
                    await wallet.connect("injected");
                    web3 = new Web3(window.ethereum);
                    const accounts = [wallet.account];
                    console.log("account is ----->",accounts[0])
                    setAccount(accounts[0]);
                }
            }
        }

        let price = await getPrice();
        console.log("price is---->",price);
        let amount=(inputAmount*price)+(inputAmount*0.10)*price;
        amount=amount.toFixed(4)
        setOutputAmount(amount)
        console.log("amount is --->",amount)
        console.log("input ammount is---->",inputAmount)

    }, [inputAmount])

    useEffect(async () => {
        (async () => {
            try {
                const queryParams = new URLSearchParams(window.location.search);
                const id = queryParams.get("id");
                console.log("id----------->", id);
                console.log("here I am");
                localStorage.setItem("ref", id);
                setRef(id);
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, [account]);   

    useEffect(() => {
        (async () => {
            try {
                if (account) {
                    let link = window.location.origin.toString()+'/?id='+account
                    console.log("ref------>", link);
                    setRefLink(link);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [account]);



    useEffect(async () => {
        (async () => {
            try {
                if (account) {
                    let trxFrom = account
                    console.log("ref------>", trxFrom);
                    setAmtTrxFrom(trxFrom);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [account]);



    useEffect(async () => {
        (async () => {
            try {
                let res = await checkAllowance();
                console.log("res--------->", res);
                if (res === true) {
                    setButtonName("BUY HFC");
                    setMsg("Buy Most Trusted Tokens Now");
                } else {
                    setButtonName("APPROVE");
                    setMsg("After inputting the amount you would like to purchase, click on approve. After the confirmation is received, come back and enter the same amount and then click on Buy HFC you purchase will be completed.");
                }

            } catch (e) {
                console.log(e);
            }
        })(); 
    }, [account,inputAmount]); 

    //Sell Out Put in BUSD

    useEffect(async() => {
        // const web3 = new Web3(window.ethereum);
        // const accounts = await web3.eth.getAccounts();
        //const provider = await detectEthereumProvider();
        //let web3 = await getWeb3();
        let web3;
        if(wallet.status === "connected"){
            //web3 = new Web3(provider);
            // //const web3 = await getWeb3_2();
            //const accounts = await web3.eth.getAccounts();
            const accounts = [wallet.account]
            console.log("account is ----->",accounts[0])
            setAccount(accounts[0]);
        } else {
            //const provider = await detectEthereumProvider()
            if(window.sessionStorage.getItem("Selected_account") && window.sessionStorage.getItem("Provider") == "walletconnect") {
                await wallet.connect("walletconnect");
                if(wallet.status === "connected") {
                    web3 = new Web3(wallet.ethereum);
                    const accounts = [wallet.account];
                    console.log("account is ----->",accounts[0])
                    setAccount(accounts[0]);
                } 
            }else {
                if(window.sessionStorage.getItem("Selected_account")) {
                    await wallet.connect("injected");
                    web3 = new Web3(wallet.ethereum);
                    const accounts = [wallet.account];
                    console.log("account is ----->",accounts[0])
                    setAccount(accounts[0]);
                }
            }
        }
        //setAccount(props.account);
        let price = await getPrice();
        console.log("price is---->",price)

        let sAmount=(sellAmount*price) - (sellAmount*0.10)*price;
        sAmount=sAmount.toFixed(4)
        setSellOutputAmount(sAmount)
        console.log("sAmount is --->",sAmount)

        console.log("input ammount is---->",sellAmount)


    }, [sellAmount])


    const _transferAmount=async()=>{
        try{
            if(account && transferValue){
                let value=parseInt(transferValue)
                value= new BigNumber(value).multipliedBy(new BigNumber(10).exponentiatedBy(new BigNumber(18)));
                console.log("transfer function is calling",value);

                let res=await transferAmount(recAddress,value,account, wallet.ethereum);
                if (res.status == true) {
                    toast.success("Transaction successful");
                    window.location.reload()
                } else {
                    toast.error("Transaction Failed");
                }
            }
        }catch(e){
            console.log(e)
        }
    }

    const purchaseToken = async () => {
        try {
            if (wallet.account && inputAmount) {
                setTransactionOnGoing(true);
                setIsDisabled(true);
                console.log("account in purchse toke is --->",account)
                console.log("ref in purchase",ref)
                let res = await buyToken(
                    inputAmount,
                    ref?ref:DEFAULT_ADDRESS,
                    wallet.account, wallet.ethereum,
                );

                // setIsDisabled(false);
                if (res.status == true) {
                    toast.success("Transaction successful");
                    window.location.reload()
                } else {
                    toast.error("Transaction Failed");
                }

                setTransactionOnGoing(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const _sellToken=async()=>{
        try {
            if (account && sellAmount) {
                setTransactionOnGoing(true);
                setIsDisabled(true);
                console.log("account in purchse toke is --->",account)

                let res = await sellToken(
                    sellAmount,
                    account,
                    wallet.ethereum
                );

                setIsDisabled(false);
                if (res.status == true) {
                    toast.success("Transaction successful");
                    window.location.reload()
                } else {
                    toast.error("Transaction Failed");
                }

                setTransactionOnGoing(false);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const checkAllowance = async () => {
        try {
            console.log("reached checkallowance", account, outputAmount);
            if (account && outputAmount) {
                let res = await checkIfApproved(inputAmount,account,wallet.ethereum);
                return res;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const approveToken = async () => {
        try {
            if (wallet.account && inputAmount) {
                setTransactionOnGoing(true);
                setIsDisabled(true);
                let res = await approveTokens(
                    wallet.account,
                    wallet.ethereum
                );

                setIsDisabled(false);
                if (res.status == true) {
                    alert("Transaction successful");
                    window.location.reload();
                } else {
                    alert("Transaction Failed");
                }
                setTransactionOnGoing(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    function copyToClipboard(e) {
        if (!refLink) return;
        var textField = document.createElement("textarea");
        textField.innerText = refLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();

        setCopySuccess("Copied!");
        toast.success("Referral Link Copied");
        console.log("refferal link is copied------->", refLink);
    }

    return (
        <div className="wallet-tab">
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-0"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="CoinBuy" title="BUY" className="text-center">
                    <Form.Label htmlFor="basic-url"></Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="0.0000"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={inputAmount}
                            onChange={(e)=>{
                                setInputAmount(e.target.value)
                            }}
                        />
                        <Button variant="danger" id="button-addon2">
                            HFC
                        </Button>
                    </InputGroup>
                    <RiArrowUpDownFill size="30" className="dark-red" />
                    <Form.Label htmlFor="basic-url"></Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            type="number"
                            placeholder="0.0000"
                            value={outputAmount}
                        />
                        <Button variant="danger" id="button-addon2">
                            BUSD
                        </Button>
                    </InputGroup>
                    <Button variant="danger" className="w-100 my-4" 
                        onClick={() => {
                            buttonName == "APPROVE"
                                ? approveToken()
                                : purchaseToken();
                        }}>
                        {wallet.account?buttonName:"you are off chain"}
                    </Button>
                    <p className="walltemag white fs-13 fw-regular mb-3">{wallet.account?Msg:"Do Connect Your Wallet Now"}</p>
                    <Form.Label htmlFor="basic-url">Refferal Code</Form.Label>
                    <InputGroup className="mb-0">

                        <FormControl
                            placeholder="Buy HFC For Refferal Code"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={refLink}
                        />
                        <Button variant="danger" id="button-addon2" onClick={(e)=>{
                            copyToClipboard()
                        }}>
                            COPY
                        </Button>
                    </InputGroup>

                </Tab>
                <Tab eventKey="CoinSell" title="Sell" className="text-center">
                    <Form.Label htmlFor="basic-url">From</Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="0.0000"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={sellAmount}
                            onChange={(e)=>{
                                SetSellAmount(e.target.value);
                            }}
                            //value={input}
                        />
                        <Button variant="danger" id="button-addon2">
                            HFC
                        </Button>
                    </InputGroup>
                    <RiArrowUpDownFill size="30" className="dark-red" />
                    <Form.Label htmlFor="basic-url">To</Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="0.0000"
                            aria-label=""
                            aria-describedby="basic-addon2"

                            value={sellOutputAmount}
                            // value={outputAmount}
                        />
                        <Button variant="danger" id="button-addon2">
                            BUSD
                        </Button>
                    </InputGroup>
                    <Button
                        variant="danger"
                        className="w-100"
                        onClick={()=>{
                            _sellToken();
                        }}

                    >
                        Sell
                    </Button>
                </Tab>
                <Tab
                    eventKey="CoinSendReceive"
                    title="Send Receive"
                    className="text-center"
                >
                    <Form.Label htmlFor="basic-url">From</Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="Your Wallet Address"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={amtTrxFrom}
                        />
                        <Button variant="danger" id="button-addon2">
                            Address
                        </Button>
                    </InputGroup>

                    <Form.Label htmlFor="basic-url">Amount</Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="0.0000"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={transferValue}
                            onChange={(e)=>{
                                setTransferValue(e.target.value);
                            }}
                        />
                        <Button variant="danger" id="button-addon2">
                            HFC
                        </Button>
                    </InputGroup>

                    <RiArrowUpDownFill size="30" className="dark-red" />
                    <Form.Label htmlFor="basic-url">To</Form.Label>
                    <InputGroup className="mb-4">
                        <FormControl
                            placeholder="Receiver's Wallet Address"
                            aria-label=""
                            aria-describedby="basic-addon2"
                            value={recAddress}
                            onChange={(e)=>{
                                setRecAddress(e.target.value);
                            }}
                        />
                        <Button variant="danger" id="button-addon2">
                            Address
                        </Button>
                    </InputGroup>
                    <Button variant="danger" className="w-100" onClick={()=>{
                        _transferAmount();
                    }}>
                        Transfer
                    </Button>
                </Tab>
            </Tabs>
            <ToastContainer/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
    };
};

export default connect(mapStateToProps)(WalletTab);
