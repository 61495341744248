import React from "react";
import Footer from "../../Component/Layout/Footer";
import Header from "../../Component/Layout/Header";
import { Row, Col, Container } from "react-bootstrap";
import PageBanner from "../../Component/Layout/PageBanner";

export default function Guides() {
  return (
    <>
      <Header />
      <PageBanner PageTitle="GITHUB" />
      <section className="pagesection">
        <Container className="py-5">
          <Row>
            <Col>
              <h2> </h2>
              <p>
              Curious about our innovation feel free to visit our Github page to get access to
list of open-source codes.</p>
<p>
<a href="https://github.com/wilkont/Hotfriescoin">https://github.com/wilkont/Hotfriescoin</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
