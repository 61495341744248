import { React, useState, useEffect, } from "react";
import { Navbar, Nav, NavDropdown, Container, Image } from "react-bootstrap";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
// import { ethers } from "ethers";
// import DarkMode from "./DarkMode";
// //import AccountModal from "../header/Accountmodal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import Web3 from "web3";
// import detectEthereumProvider from "@metamask/detect-provider";
import {useWallet} from 'use-wallet';
import WalletModal from "./WalletModal";

/*
//const provider = detectEthereumProvider();
//  Create WalletConnect Provider
export const global_provider = new WalletConnectProvider({
  infuraId: "1b6b6b3459dc49a78c408ecc11ff6543",

  rpc: {
    56: "https://bsc-dataseed.binance.org/"
  },
});


// let web3Modal = null;
let web3 = null;
// let provider = null;

function initWeb3(provider) {
  const web3 = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

export const Web3_provider_2 = async () => {
  if (global_provider && window.sessionStorage.getItem("Provider")) {
    await global_provider.enable()
    const web3 = new Web3(global_provider);
    const accounts = await web3.eth.getAccounts()
    return web3;
  } else {
    console.log("provider is not found")
    const web3 = new Web3(await detectEthereumProvider());
    return web3;
  }
}
*/

export default function Header(props) {
  const wallet = useWallet();
  const [scroll, setScroll] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  /*
  const onConnect = async () => {
    //Detect Provider
    try {
      // setIsChecked(!isChecked);
      //provider = await web3Modal.connect();
      await global_provider.enable()


     // window.sessionStorage.setItem("Provider", global_provider);
      if (!global_provider) {
        console.log("no provider found");
      } else {
        web3 = initWeb3(global_provider);
        //await ConnectWallet();
      }
      // const chainId = await web3.eth.net.getId();
      const accounts = await web3.eth.getAccounts();
      let selected = accounts[0]
      setDefaultAccount(accounts[0]);
      window.sessionStorage.setItem("Provider", global_provider);
      window.sessionStorage.setItem("Selected_account", accounts[0])

      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
      // MetaMask does not give you all accounts, only the selected account
      //window.sessionStorage.setItem("selected_account", accounts[0]);

      // if (chainId.toString() !== process.env.REACT_APP_CHAIN_ID) {
      //   setWrongNetwork(true);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  //on disconnect button
  const onDisconnect = useCallback(async () => {
    if (!web3) {
      window.sessionStorage.removeItem("selected_account");
    }
    if (web3) {
      // setIsChecked(!isChecked);
      // const chainId = await web3.eth.net.getId();
      // props.dispatch(
      //   accountUpdate({
      //     account: null,
      //   })
      // );
    }
    window.sessionStorage.removeItem("Selected_account");
    window.sessionStorage.removeItem("Provider");
    await setDefaultAccount(null);
    // if (web3Modal) await web3Modal.clearCachedProvider();
    // web3Modal = null;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.disconnect();
    }
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      window.location.reload(true);
    }
  });
  */

  return (
    // <header>
    //   <Navbar
    //     collapseOnSelect
    //     expand="lg"
    //     fixed="top"
    //     className={scroll ? "header-black" : "header-transp"}
    //   >
    //     <Container>
    //       <Link to="/" className="navbar-brand">
    //         <Image src={logo} className="img-fluid" />
    //       </Link>
    //       <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //       <Navbar.Collapse id="responsive-navbar-nav">
    //         <Nav className="ms-auto">
    //           <Nav.Link href="#BuyNow">Buy / Sell</Nav.Link>
    //           <DarkMode/>
    //           <AccountModal/>
    //           <Nav.Link href="#link" onClick={connectWalletHandler}>
    //             connect wallet :{defaultAccount}
    //           </Nav.Link>
    //         </Nav>
    //       </Navbar.Collapse>
    //     </Container>
    //   </Navbar>
    // </header>

    <header>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        className={scroll ? "header-black" : "header-transp"}
      >
        <Container>
          <Link to="/" className="navbar-brand">
            <Image src={logo} className="img-fluid vert-move" />
          </Link>
          <Navbar.Toggle />
          <Nav className="ms-start justify-content-end Mobile-nav d-md-none d-sm-block">
            <Nav.Link href="#WalleteSection">Buy/sell</Nav.Link>
            <WalletModal/>
          </Nav>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="https://www.hotfriescoin.com/#WalleteSection">Buy / Sell</Nav.Link>
              {/*<AccountModal/>*/}
              {/*<Nav.Link href="#link" onClick={defaultAccount?onDisconnect:onConnect}>
                {window.sessionStorage.getItem("Selected_account")
          ? window.sessionStorage.getItem("Selected_account").slice(0, 5)
          : "Connect Wallet"}
  </Nav.Link>*/}
  {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#">Item One</NavDropdown.Item>
                <NavDropdown.Item href="#">Item Two</NavDropdown.Item>
                <NavDropdown.Item href="#">Item Three</NavDropdown.Item>
              </NavDropdown> */}
              <WalletModal/>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
